body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@font-face {
  font-family: "Visby CF SemiBold"; /*Can be any text*/
  src: local("Visby-CF-SemiBold"),
    url("./assets/fonts/Visby-CF/VisbyCF-DemiBold.otf") format("truetype");
}

@font-face {
  font-family: "Visby CF Bold"; /*Can be any text*/
  src: local("Visby-CF-Bold"),
    url("./assets/fonts/Visby-CF/VisbyCF-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Visby CF Heavy"; /*Can be any text*/
  src: local("Visby-CF-ExtraBold"),
    url("./assets/fonts/Visby-CF/VisbyCF-ExtraBold.otf") format("truetype");
}


@font-face {
  font-family: "Visby CF Medium"; /*Can be any text*/
  src: local("Visby-CF-Medium"),
    url("./assets/fonts/Visby-CF/VisbyCF-Medium.otf") format("truetype");
}


@font-face {
  font-family: "Visby CF Light"; /*Can be any text*/
  src: local("Visby-CF-Light"),
    url("./assets/fonts/Visby-CF/VisbyCF-Thin.otf") format("truetype");
}

@font-face {
  font-family: "Visby CF Regular"; /*Can be any text*/
  src: local("Visby-CF-Regular"),
    url("./assets/fonts/Visby-CF/VisbyCF-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Visby CF Italic"; /*Can be any text*/
  src: local("Visby-CF-Italic"),
    url("./assets/fonts/Visby-CF/VisbyCF-RegularOblique.otf") format("truetype");
}
   
    
@font-face {
  font-family: "Ethos Nova Bold"; /*Can be any text*/
  src: local("EthosNova-Bold"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Ethos Nova Heavy"; /*Can be any text*/
  src: local("EthosNova-Heavy"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Ethos Nova Medium"; /*Can be any text*/
  src: local("EthosNova-Medium"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Ethos Nova Light"; /*Can be any text*/
  src: local("EthosNova-Light"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Ethos Nova Regular"; /*Can be any text*/
  src: local("EthosNova-Regular"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ethos Nova Italic"; /*Can be any text*/
  src: local("EthosNova-Italic"),
    url("./assets/fonts/Ethos-Nova/EthosNova-Italic.ttf") format("truetype");
}


*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #6263660c;
}
*::-webkit-scrollbar-thumb {
  background-color: #6263661a;
  border-radius: 20px;
}
select option {
  background-color: red;
  font-weight: bold;
  color: red;
}

/* Estilize a opção selecionada */
select option:checked {
  background-color: red;
  font-weight: bold;
  color: darkgreen;
}

.ql-size-small {
  font-size: small;
}

.ql-size-large {
  font-size: larger;
}

.ql-size-huge {
  font-size: xxx-large;
}


.ql-align-right{
  text-align: right;
}

.ql-align-left{
  text-align: left;
}

.ql-align-center{
  text-align: center;
}

.ql-align-justify{
  text-align: justify;
}

.ql-indent-1{
  text-indent: 8px;
}
.ql-indent-2{
  text-indent: 16px;
}
.ql-indent-3{
  text-indent: 24px;
}

.ql-indent-4{
  text-indent: 30px;
}

.ql-indent-5{
  text-indent: 36px;
}

.ql-indent-6{
  text-indent: 42px;
}

.ql-indent-7{
  text-indent: 48px;
}
.ql-indent-8{
  text-indent: 54px;
}



