.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-gallery-slides {   
  display: flex;
  border-radius: 59px;
  border: 1px solid #E7FF00;
  min-width: 60vw;
  min-height: 80vh;
  max-height: 90vh;
  background:rgba(217,217,217,0.1);
  backdrop-filter: blur(6.5px); align-items: center;
}

.image-gallery-slide {
  margin: auto;
  min-height: 80vh; 
  max-height: 90vh;
}

@media (max-width: 1024px) {
  .image-gallery-slides {
    border-radius: 32px;
    border: 1px solid #E7FF00;
    min-width: 60vw;
    min-height: 80vh;
    background:rgba(217,217,217,0.1);
    backdrop-filter: blur(6.5px);
    display: flex;
  }
}